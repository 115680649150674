.ratingStars {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 10px;
}

.reviewCount {
  padding-left: 5px;
  font-size: 16px;
}
@media all and (max-width: 520px) {
  .reviewCount {
    width: 100%;
    margin-top: 10px;
  }
}

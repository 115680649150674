.navlink-sublinks-container a {
  padding: 5px;
  width: 100%;
}
.navlink-sublinks-container {
  display: none;
}



.stores-link-caret {
  cursor: pointer;
  position: absolute;
  transform: translateY(calc(50% + 2px));
}
@media only screen and (max-width: 1023px) {
  .cross-btn-mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: rgb(102, 102, 102);
    padding: 5px 10px 5px 10%;
    margin-bottom: 30px;
  }
  .cross-btn-mobile-menu img {
    max-height: 50px;
  }

  .cross-btn-mobile-menu > i {
    font-size: 38px !important;
  }
  #nav-menu-drawer.nav-wrapper.actual-nav {
    background-color: rgb(69, 155, 66);
    margin: 0;
    width: 100%;
    height: 100% !important;
  }

  #nav-menu-drawer.nav-wrapper.actual-nav > div > ul.staticMenu {
    padding: 5% 10%;
    width: 100%;
  }

  #nav-menu-drawer ul.staticMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #nav-menu-drawer ul.staticMenu:first-of-type {
    border-bottom: 1px solid #fff;
  }

  #nav-menu-drawer ul.staticMenu li {
    float: none;
    margin-bottom: 30px !important;
  }

  #nav-menu-drawer ul.staticMenu li > a {
    color: #fff !important;
    font-size: 1em !important;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 25px;
  }

  #nav-menu-drawer .search-bar-wrapper {
    background-color: #FFFFFF;
  }

  #nav-menu-drawer input[type=text] {
    background-color: #FFFFFF;
    color: #000000;
    border: none;
  }

  #nav-menu-drawer input[type=text]::placeholder, #nav-menu-drawer .search-bar-wrapper i {
    color: #000000 !important;
  }
}

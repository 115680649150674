.buy-box-qty-price-container {
  display: flex;
  padding: 0 15px;
  width: 95%;
  margin: 0 auto;
  padding-top: 20px;
  flex-wrap: wrap;
}

.inStock-wrapper {
  font-size: 20px;
}

.inStock-wrapper label {
  font-weight: 600;
}

.buy-box-qty-wrapper {
  width: 100%;
}

.buy-box-price-wrapper {
  display: flex;
  align-items: center;
}

.addToCartBtn.wiggle {
  animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes wiggle {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media all and (max-width: 768px) {
  .inStock-wrapper {
    font-size: 16px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(80, 80, 80);
}

.loader {
  border: 8px solid #c8c8c8; /* Light grey */
  border-top: 8px solid #0468eb; /* Blue */
  border-radius: 50%;
  width: 75%;
  height: 75%;
  animation: spin 1s linear infinite;
  background-color: transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

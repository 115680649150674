.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  background-color: #fff;
  width: 50%;
  height: 50%;
  min-height: 300px;
  box-shadow: 0 0 4px 1px #00000066;
  display: flex;
  flex-wrap: wrap;
}

.content {
  padding: 10px;
  width: 100%;
  height: 60%;
}

.actionsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.title,
.loginTitle {
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 10px;
  color: #fff;
  width: 100%;
}

.title i {
  cursor: pointer;
  font-size: 32px;
}

.backToFileUploadBtn,
.skipFileUploadBtn {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  color: rgb(15, 15, 218);
  float: left;
}

.backToFileUploadBtn:hover,
.skipFileUploadBtn:hover {
  text-decoration: underline;
}

.loginContent {
  width: 100%;
  text-align: center;
}

.loginContent h6 {
  font-size: 22px;
  width: 100%;
}
.loginContent small {
  color: #666;
  font-size: 16px;
}
.sendMessageBtn,
.loginBtn {
  padding: 10px;
  background-color: #2d509f;
  color: #fff;
  margin-left: auto;
}

.loginBtn {
  margin-top: 20px;
  width: 50%;
}

.contentTitle {
  margin-bottom: 10px;
}

.messageInput {
  box-sizing: border-box;
  line-height: initial;
  border: 1px solid #ddd;
  height: 100%;
  margin: 0px;
}

.messageInput:focus {
  border: 1px solid #83a3ec;
}

.messageInputWrapper {
  height: calc(100% - 20px);
}
.messageSendSuccess {
  color: #28a745;
}
.messageSendFailure {
  color: #dc3545;
}
.fileInput {
  cursor: pointer;
  padding: 10px 0 0 0 !important;
  border: none !important;
}

@media all and (max-width: 900px) {
  .wrapper {
    width: 75%;
    height: 75%;
  }
}

@media all and (max-width: 600px) {
  .wrapper {
    width: 100%;
    height: 100%;
    transform: unset;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 998;
  opacity: 1;
  visibility: unset;
  transform-origin: unset;
  border-radius: unset;
}

.message-item--row {
  padding: 10px;
  cursor: pointer;
  box-shadow: inset 0 -1px 1px 0px #c8c8c8;
  background-color: #c8c8c822;
}

.message-item--row.unread {
  font-weight: 600;
  background-color: unset;
}

.message-item--row:hover {
  box-shadow: inset 1px 0 0 #c8c8c8, inset -1px 0 0 #c8c8c8,
    0 1px 2px 0 rgba(0, 0, 0, 0.25), 0 1px 2.5px 1px rgba(0, 0, 0, 0.25);
}

.message-item--column {
  padding: 5px;
}

.message-item__unread-counter {
  display: none;
  position: absolute;
  background-color: rgb(49, 63, 141);
  color: #fff;
  border-radius: 50%;
  min-height: 18px;
  min-width: 18px;
  text-align: center;
  line-height: 18px;
}

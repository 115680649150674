.compare-items--img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

@media all and (max-width: 768px) {
  .compare-items-container,
  .compare-table-data-cell,
  .compare-table-data-header {
    min-width: 160px !important;
  }

  .all-compare-items {
    overflow: auto;
    font-size: 12px;
  }
}

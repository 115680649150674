.myContainer {
  margin: 0 5% 0 5%;
  border-bottom: 1px solid #eee;
}

.banner {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.storeInfoWrapper {
  margin: 0 5%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 3fr 5fr;
  grid-gap: 50px;
  margin-bottom: 20px;
}

.storeLogo img {
  width: 100px;
  border-radius: 50%;
}

.storeLocation {
  color: rgb(80, 80, 80);
}

.seeReviews {
  cursor: pointer;
  text-decoration: underline;
}

/* .seeReviews:hover {
  color: rgb(8, 8, 184);
} */

.storeInfoMain {
  display: flex;
  align-items: center;
}

.storeName {
  margin-top: 0;
}

.reviewsRating {
  margin-top: 10px;
  display: flex;
}

.reviewsRatingText {
  margin: 0 !important;
  padding-top: 6px;
  padding-left: 10px;
  font-size: small;
}

.storeDescription p {
  line-height: 1.7;
  font-style: italic;
  color: gray;
}

.storeDescription a {
  display: block;
  margin-top: 20px;
  font-size: small;
  text-decoration: underline;
}

.mapContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  grid-gap: 20px;
}

.myDiv {
  background: blue;
  height: 400px;
}

.searchIcon {
}

.searchIcon.active {
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  height: 45px;
  padding: 0 5%;
  background-color: #fff;
  box-shadow: 0 1px 4px #00000066, inset 0 0 1px #000;
}

.input {
  color: #fff;
  margin: 0 !important;
  width: 100% !important;
  padding-left: 5px !important;
  border-bottom: none !important;
}

.iconWrapper {
  width: 100%;
}

.rtl {
  direction: rtl !important;
  & body {
    direction: rtl !important;
  }

  & .qtyControlsInput {
    border: 0 !important;
    border-left: 3px solid !important;
  }
  & .magnified-image-container {
    left: 0% !important;
    right: 100% !important;
    margin-right: 10px;
    margin-left: 0 !important;
    direction: ltr;
  }
  & .minicart-new-count {
    margin-right: unset !important;
    margin-left: 5px !important;
  }
  & .box-wrapper {
    padding-left: unset !important;
    padding-right: 20px;
  }
  & .featured-items-title {
    letter-spacing: unset !important;
  }
  & .search-form-container {
    padding-left: 15px !important;
  }
  & .cross-btn-mobile-menu {
    right: unset !important;
    left: 0 !important;
    margin-right: unset !important;
    padding: 5px 10% 5px 10px;
  }

  & .minicart-title {
    padding: 10px 20px 10px 10px;
  }
  & .wishListTitleArabic {
    justify-content: space-between !important;
    padding: 10px 20px 10px 10px !important;
    & i {
      margin-right: auto !important;
      margin-left: unset !important;
    }
  }

  & .language-active {
    &:after {
      left: 4px !important;
    }
  }
  .main-language {
    & .country-options {
      & .more-country {
        margin-left: unset !important;
        margin-right: 20px !important;
      }
    }
    & select {
      text-indent: 5px;
    }
  }
  #stepper svg:not(:root) {
    left: 0 !important;
    right: unset !important;
  }
  // Lucas jan 13 2020
  & .header-container,
  & .nav-menu-content-desktop,
  & .sub-nav-bread {
    margin-left: 0px;
    margin-right: 5%;
  }

  & .pagination-container {
    float: left !important;
  }

  & .sub-nav-menu {
    margin-left: 0px;
    margin-right: 5%;
  }

  & .navlink-stores:hover .navlink-sublinks-container {
    transform: translateX(25%) !important;
  }

  & .select-div::after {
    right: 5px;
  }

  & .pagination li {
    float: right !important;
    border-left: none;
    border-right: 1px solid #333333;
  }

  & .pagination li:first-of-type {
    border-right: unset;
  }

  & ul.breadcrumb li a {
    float: right;
  }
  & .price-cafe-wrap-content {
    direction: ltr;
  }

  @media only screen and (max-width: 768px) {
    .nav-menu-wrapper i.mobile-nav-icon {
      left: auto;
    }

    .magnified-image-container {
      right: 0 !important;
    }

    .logo-container {
      margin-right: 30px;
      margin-left: 10px;
    }

    nav .brand-logo img {
      max-width: 200px !important;
    }

    .language-div {
      margin-left: 10px;
    }
  }
  .checkmark-box {
    margin: 0 0 0 5px !important;
  }
  .productCount {
    margin-left: unset !important;
    margin-right: 4px !important;
  }
}

.wishlist-column-product {
  white-space: pre-wrap !important;
}

.wishlistIconClose {
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 9999;
}

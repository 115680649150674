.minicart-item-distname {
  font-size: 16px;
  margin-bottom: 10px;
}

.cart-supplier-wrapper {
  padding: 4%;
  margin: 15px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.mini-cart-icon-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mini-cart-icon-title-wrapper p {
  font-size: 15px;
  letter-spacing: 1px;
}

.mini-cart-supplier-total {
  margin-top: 30px;
}

.mini-cart-supplier-total b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 30, 0.3);
}

.supplier-checkout-btn {
  margin-left: auto;
  margin-top: 20px;
  padding: 10px;
  background-color: #217730;
  color: #fff;
  min-width: 200px;
  cursor: pointer;
  text-align: center;
}

.supplier-checkout-btn:hover {
  background-color: #28a03c;
}

.supplier-checkout-btn-wrapper {
  display: flex;
}

.cd-price {
  border-bottom: 1px dashed rgba(0, 0, 30, 0.2);
}

.minicart-product-image-wrapper {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 10px;
}

.minicart-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

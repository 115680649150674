.wishlist-bar--bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

@media all and (max-width: 768px) {
  .wishlist-bar--bar {
    font-size: 16px;
  }
}

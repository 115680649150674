.messaging__message-container {
  position: relative;
  float: left;
  width: 100%;
  padding: 1em;
}

.messaging__message-wrapper {
  padding: 8px;
  float: left;
  max-width: 80%;
  min-width: 25%;
  background-color: #f0f0f0;
  border-radius: 6px;
  margin-left: 5px;
}
.messaging__message--sent {
  float: right;
  background-color: #d0d7ec;
  margin: 0;
  margin-right: 5px;
}

.messaging__message--received::before {
  content: "";
  position: absolute;
  border-left: 1.5em solid transparent;
  border-top: 1em solid #f0f0f0;
  width: 0;
  height: 0;
  top: 1em;
  left: 6px;
}

.messaging__message--sent::after {
  content: "";
  position: absolute;
  border-right: 1.5em solid transparent;
  border-top: 1em solid #d0d7ec;
  width: 0;
  height: 0;
  top: 1em;
  right: 6px;
}

.messaging__message--sent .messaging__message--date {
  text-align: right;
}

.messaging__message--from::before {
  content: "from: ";
  font-size: 0.8em;
  color: #666;
}

.messaging__message--from {
  color: #444;
}

.messaging__message--date {
  font-size: 0.8em;
  color: #666;
  text-align: left;
}

.messaging__message--content {
  padding: 8px 5px;
  font-size: 1em;
}
.message-images-wrapper {
  padding: 5px;
}
.message-image {
  height: 50px;
  padding-right: 5px;
}

.message-image.active {
  height: auto;
}

.message-enlarged-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
}

.message-enlarged-image-wrapper {
  display: flex;
  position: relative;
  margin: 2.5%;
  transform: translateY(-50%);
  top: 50%;
}

.message-enlarged-image-wrapper i {
  color: #fff;
  font-size: 2em;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
}

.message-enlarged-image-wrapper i:hover,
.message-enlarged-image-wrapper i:focus {
  transition: all 0.4s ease-in-out;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000;
}

.message-enlarged-image {
  box-shadow: 0 1px 4px 2px #000;
  max-width: 100% !important;
  height: auto;
}

.image-and-icon-container {
  display: flex;
  position: relative;
  margin: 0 auto;
}

.container {
  padding: 10px;
}

.wrapper {
  display: flex;
  height: 92px;
  width: 92px;
  border: 2px solid #666;
  position: relative;
  cursor: pointer;
}

.galleryViewPhotosWrapper {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.container[renderedby="horizontalScrolling"] {
  padding: 0px;
  height: 100%;
  width: 100%;
  position: relative;
}

.photoModalGalleryViewContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.photoModalGalleryViewContainer span {
  font-weight: bold;
}

.container[renderedby="horizontalScrolling"] .wrapper {
  height: 100%;
  width: 100%;
}

.reviewImage {
  max-width: 100% !important;
  object-fit: cover;
}

.reviewedBy {
  padding: 5px 0 5px 5px;
}

.reviewContent {
  padding-left: 5px;
}

.reviewContent label {
  padding: 10px 0 !important;
}

.reviewDetails {
  letter-spacing: initial;
  line-height: initial;
}

/* Modal starts here */
.photoModalContainer {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(39, 40, 44, 0.9);
  cursor: pointer;
}

.photoModalWrapper {
  width: 85%;
  height: 85%;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.9);
  background-color: #fff;
  cursor: initial;
}

.photoModalHeader {
  padding: 0 10px;
  height: 38px;
  background-color: #c8c8c8;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
  box-shadow: inset 0 -1px 0 rgba(13, 13, 13, 0.34);
}

.photoModalHeader i {
  margin-left: auto;
  cursor: pointer;
}

.photoModalHeader i:focus {
  box-shadow: 0px 0px 2px 4px #f34a07;
}

.photoModalBodyContainer {
  position: relative;
  height: calc(100% - 38px);
}
.photoModalBody {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.photoModalImageContainer {
  width: 55%;
  height: 100%;
  position: relative;
}

.photoModalImageWrapper {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.photoModalImageArrowsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.photoModalImageArrowsWrapper:hover {
  opacity: 0.4;
}

.photoModalImageArrowLeft,
.photoModalImageArrowRight {
  width: 40%;
  height: 100%;
  position: relative;
  text-shadow: 0 0 6px black;
}

.photoModalImageArrowRight {
  width: 60%;
  margin-left: auto;
}

.photoModalImageArrowLeft i,
.photoModalImageArrowRight i {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 64px;
  font-weight: bolder;
  color: #fff;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-self: center;
}
.photoModalImageArrowRight i {
  right: 10px;
  left: unset;
}

.photoModalReviewContainer {
  padding-left: 10px;
  width: 45%;
}

.photoModalImageBox {
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoModalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media all and (max-width: 768px) {
  .photoModalWrapper {
    width: 100%;
    height: 100%;
  }
  .photoModalBody {
    overflow: auto;
  }
  .photoModalImageContainer {
    width: 100%;
    height: 80%;
    box-shadow: 0 0 2px 1px #c8c8c8;
  }

  .photoModalReviewContainer {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 2px 1px #c8c8c8;
    margin-top: 10px;
  }
}

/* Modal ends here */

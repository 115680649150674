.collectionCard {
  border: 1px solid #c8c8c8;
  margin: 20px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.collectionCardImg {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.collectionText {
  font-weight: 600;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 10px;
  background-color: rgb(0, 0, 0, 0.5);
}

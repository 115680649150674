.container.arabic:not(.active) {
  transform: translateX(-100%);
}
.outerContainer {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4; /*compare container has index of 3 so our wishlist should appear on top, should not use values like 999 */
}
.container {
  background-color: #f5f5f5;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.active {
  visibility: visible;
  transition: all 0.3s ease-in-out;
  transform: translateX(0);
}

.wishListTitle {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: auto;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #666;
  color: #fff;
  z-index: 1;
}

.wishListTitle i {
  font-size: 38px !important;
  cursor: pointer;
}

.wrapper {
}

.wishListWrapper {
  overflow: auto;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #eee;
}

.wishItemWrapper {
  margin: 15px;
  box-shadow: 0 0 4px 0px #666;
  background-color: #fff;
  display: flex;
  padding: 10px;
}

.wishItemWrapper:first-of-type {
  margin-top: 70px;
  cursor: pointer;
}

.wishItemImage {
  max-width: 33%;
  padding-right: 10px;
}

.wishItemImage img {
  width: 100%;
}

.wishItemDetails {
  line-height: initial;
  font-size: 12px;
  width: 100%;
}

.wishItemDesc {
  font-size: 14px;
  line-height: initial;
}

.wishItemPrice {
  padding: 5px 0;
  font-weight: 600;
  font-size: 16px;
}

.wishItemActionWrapper {
  padding: 10px;
}

.wishItemCode {
  padding: 5px 0;
}

.wishItemCode label {
  color: #182c4e;
}

.wishItemRemove {
  font-size: 16px;
  background-color: #217730;
  color: #fff;
  display: flex;
}

.wishItemRemove {
  margin-left: auto;
  padding: 5px 10px;
}

.noItemsFound {
  background-color: #fff;
  margin-top: 58px;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 4px 0px #666;
  padding: 20px;
  line-height: initial;
  font-size: 16px;
}

.noItemsFoundInWish p {
  font-weight: 600;
}

@media all and (min-width: 769px) {
  .outerContainer {
  }
  .container {
    width: 600px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: unset;
    box-shadow: -1px 0 10px rgba(0, 0, 0, 0.5);
  }
  .wishListTitle {
    left: unset;
    width: 600px;
  }
}

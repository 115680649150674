/*B2B2C PRODUCT DESIGN START*/
.buyBox {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 50px;
}
.ac-skin .item-main .row {
  margin-left: 0;
  margin-right: 0;
}
.row {
  grid-area: row;
}
.qtyControlsBox {
  border: 3px solid #000000;
  margin: 0px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-flex;
  flex-direction: row-reverse;
}
.qtyControlsBtns {
  float: right;
  width: 30px;
}
.qtyControlsPlus {
  border-bottom: 2px solid #000000;
  line-height: 1.42857143;
}
.qtyControlsMinus {
  line-height: 1.42857143;
}
.qtyControlsBtns span {
  font-weight: bold;
  text-align: center;
  display: block;
  width: 100%;
  padding: 5px;
}
.buyBox #buyBoxPrice {
  font-size: 2em;
  line-height: 56px;
  float: right;
}
.buyBox p {
  font-size: 1.3em;
  line-height: 1.5em;
}
.buyBox #buyBoxAddToCartBtn,
.buyBox #buyBoxEnquiryBtn,
.buyBox #buyBoxQuoteBtn {
  margin-bottom: 15px;
  display: block;
  z-index: -1;
}
.buyBox #buyBoxAddToCartBtn .addToCartBtn,
.addToCartBtn.cartBtn {
  background-color: #217730;
}

.buyBox #buyBoxAddToCartBtn.out-of-stock .addToCartBtn,
.addToCartBtn.cartBtn {
  cursor: not-allowed;
  background-color: #555;
}
.buyBox .addToCartBtn,
.buyBox .addToCartBtn i {
  font-size: 1.2em;
}
.buyBox .addToCartBtn {
  float: none;
}
.addToCartBtn {
  /* width: auto; */
  background: #494949;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 19px 20px;
  cursor: pointer;
  /* right: 0px; */
  /* bottom: auto; */
  /* position: relative; */
  text-transform: uppercase;
  font-weight: normal;
  float: right;
}
.backBtn {
  /* width: auto; */
  background: #2f4858;
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 10px 20px;

  cursor: pointer;
  /* right: 0px; */
  /* bottom: auto; */
  /* position: relative; */
  text-transform: uppercase;
  font-weight: normal;
  float: right;
}
.backBtn.standalone {
  float: unset;
  margin-left: auto;
  width: 150px;
}
.addToCartBtn {
  width: 100%;
}

.added-to-cart-modal-wrapper .addToCartBtn {
  justify-content: center;
}
.buyBox .addToCartBtn i {
  position: relative;
}
.buyBox .addToCartBtn,
.buyBox .addToCartBtn i {
  font-size: 1.2em;
}
.qtyControlsInput {
  border: 0px !important;
  margin: 0px !important;
  font-weight: bold !important;
  font-size: 1.4em !important;
  height: 62px !important;
  text-align: center !important;
  float: left !important;
  border-right: 3px solid #000000 !important;
  box-sizing: border-box !important;
  width: unset !important;
}

#bd.item h2 {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 30px;
  clear: both;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
table,
table td {
  padding: 0;
  border-collapse: collapse;
}
.itemDistributors table.table-striped tr {
  background-color: #ffffff;
}
.form-control {
  display: block;
  width: 90%;
  /* height: 34px; */
  padding: 6px 12px;
  /* font-size: 14px; */
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  background-image: none;
  border-bottom: 1px solid #9e9e9e;
  /* border: 1px solid #ccc; */
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075); */
  font-size: 13px !important;
}
.itemDistributors table.table-striped tr {
  background-color: #ffffff;
}
.itemDistributors table td .symbol,
.itemDistributors table td .sign,
.itemDistributors table td .dollars,
.itemDistributors table tr td:first-of-type {
  font-weight: bold;
}
.itemDistributors table .addToCartBtn {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  float: none;
  justify-content: center;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
tdvideo,
tt,
u,
ul,
var {
  border: 0 none;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 14px;
  line-height: 1.42857143;
  vertical-align: middle;
  border: 0px solid #f1f1f1 !important;
  text-transform: uppercase;
  white-space: nowrap;
}
.itemDistributors table.table-striped tr:nth-of-type(2n) {
  background-color: #f9f9f9;
}
.itemDistributors table td {
  text-align: center;
}
table,
table td {
  padding: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

.specs-list table.table {
  float: left;
  width: 45%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.specs-list table.table td,
.specs-list table.table th {
  width: 50%;
}
.specs-list table.table td {
  word-break: break-word;
}

/*B2B2C PRODUCT DESIGN ENDS*/
.qty-input-controls {
  margin-right: 10px;
  float: right;
}
.ctrl1 > button {
  border-left-width: 0;
  border-bottom-width: 1px;
}
.qty-control-btn > button {
  height: 28px;
  width: 25px;
  border-color: #a1c057;
  border-style: solid;
  border-width: 3px 3px 0px 0px;
  font-weight: bold;
  font-size: 22px;
  background: transparent;
}
.qty-control-btn > button:hover {
  background: transparent;
}
.qty-input-div {
  float: right;
}
.ac-skin .qty-input {
  height: 32px;
  margin-bottom: 5px;
  text-align: center;
  width: 60px;
}
.ac-skin .promos,
.ac-skin .qty-input,
.ac-skin .view-switch,
.ac-skin .view-switch a {
  display: inline-block;
}
@media only screen and (min-width: 994px) and (max-width: 1094px) {
  .item_tabs button {
    font-size: 11px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 994px) {
  .item_tabs button {
    font-size: 9px !important;
  }
}

.showroom-info {
  font-size: 14px !important;
}
.reviews-p {
  font-size: 13px !important;
}
.showroom-reviews .reviewCount {
  top: -6px !important;
}
.reviews-p a {
  color: #fe4f00 !important;
}

.showroom-info p:first-child {
  padding: 0px 0px 5px 0px !important;
  line-height: 12px !important;
}
.showroom-reviews {
  margin-top: 10px !important;
}

.ac-skin .item-pricing-border-light {
  position: unset !important;
}
.owl-carousel {
  margin: 1em 0;
}
.comment {
  border-bottom: 1px dotted #a1c057;
  padding: 10px 0px;
}

.dollars .symbol {
  font-size: 20px;
  vertical-align: top;
}
.dollars .sign {
  vertical-align: top;
  font-size: 20px;
}
.check_nationality {
  padding-top: 20px;
  padding-left: 0px;
}
.location_text {
  padding-top: 10px;
  padding-left: 0px;
  font-size: 14px;
  align-items: center;
  display: inline-flex;
}
.nationality_text {
  position: relative;
  padding-top: 7px;
}

.infoDownload {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-right: 20px;
  float: left;
}
.certInfo {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ac-skin .f-field {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding-left: 0;
}
.item-attr-section fieldset .f-row:last-child {
  border-bottom: solid 1px #cdcdcd;
  padding-bottom: 15px !important;
}

.sellerTab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.location-alignment {
  margin-top: 5px;
}
.f-row input {
  margin-right: 5px !important;
}
.font-top {
  margin-top: 10px !important;
}
.f-12 {
  font-size: 14px !important;
}
.font {
  font-size: 14px !important;
}
.price-top {
  padding-top: 0px !important;
}
.price-top2 {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
}
.sellerTab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.sellerTab button:hover {
  background-color: #ddd;
}

.sellerTab button.active {
  background-color: #ccc;
}

.review {
  padding: 6px 12px 6px 0;
  border-top: none;
  float: left;
  line-height: 30px;
}

#js-popup-body .f-field label {
  float: left;
  padding-left: 5px;
}
#js-popup-body div {
  float: left;
  padding-bottom: 5px;
}
#js-popup-footer {
  padding-bottom: 10px;
}
#image-form {
  z-index: 99999 !important;
}
#product_carousel {
  height: auto !important;
}
#product_carousel .jcarousel {
  /*height:64px */
}
.supplier-left {
  margin-bottom: 10px !important;

  float: left;
  width: auto;
  margin-right: 3%;
  font-size: 13px;
  font-style: italic;
}
.supplier-left a {
  color: #fe4f00;
  text-decoration: underline;
}
.suppler-city a {
  color: #fe4f00;
  text-decoration: underline;
}
#image-back {
  background-image: url("https://shop.sociallighting.com/assets/commercelib/showimage/img/imageback.png");
  position: relative;
  z-index: 9999 !important;
}
.percentage-value {
  padding: 7px;
  background: #f99d27;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
}
.item-attr-section fieldset {
  float: left;
  width: 100%;
  border-top: solid 1px #cdcdcd;
  padding-top: 0.5em;
}
.enquiryBtn {
  display: block;
  height: 20px;
}
.distributor-name {
  display: none;
}
.supplier-store {
  display: none;
}
@media only screen and (min-width: 320px) and (max-width: 1280px) {
  #js-popup-message {
    left: 0px;
    top: 1069px !important;
    visibility: visible;
    z-index: 2000;
    width: 100%;
  }
  .location-alignment {
    margin-top: 13px !important;
  }
}
.itemTitle {
  width: 100%;
  float: left;
  padding: 0px 0px 3px 0px !important;
}
#temp_family {
  font-size: 20px;
  padding-bottom: 10px;
}

#mainGridDiv::before {
  content: "::before element";
  order: 9999;
  visibility: hidden;
  display: none;
  height: 0;
  overflow: hidden;
}

#mainGridDiv::after {
  content: "::after element";
  order: 9999;
  visibility: hidden;
  display: none;
  height: 0;
  overflow: hidden;
}

#leftGrid {
  grid-area: leftGrid;
}
#rightGrid {
  grid-area: rightGrid;
}

#imageGrid {
  grid-area: imageGrid;
  min-height: 400px;
}
#certGrid {
  grid-area: certGrid;
}
#titleGrid {
  grid-area: titleGrid;
  padding-right: 15px;
}
#socialGrid {
  grid-area: socialGrid;
}
#reviewGrid {
  grid-area: reviewGrid;
}
#optionsPriceGrid {
  grid-area: optionsPriceGrid;
}

#supplerCityGrid {
  grid-area: supplerCityGrid;
}
#fixtureGrid {
  grid-area: fixtureGrid;
}
#callForPriceGrid {
  grid-area: callForPriceGrid;
}
#specGrid {
  grid-area: specGrid;
}
#addToCardGrid {
  grid-area: addToCardGrid;
  z-index: 0;
}
#locationGrid {
  grid-area: locationGrid;
}
#nationalityGrid {
  grid-area: nationalityGrid;
}
#showroomSection {
  grid-area: showroomSection;
}
#otherInfoGrid {
  grid-area: otherInfoGrid;
}
#priceGrid {
  grid-area: priceGrid;
}

.clearfix {
  grid-area: clearFix;
}
.dummyDiv {
  grid-area: dummyDiv;
}
.item-click {
  grid-area: itemClick;
}
.item_mm {
  grid-area: itemMM;
}
.floatClear {
  grid-area: floatClear;
}
.row {
  grid-area: row;
}

.price {
  width: auto !important;
  font-size: 24px;
  padding-right: 0% !important;
}

.qty-input {
  height: 50px !important;
  width: 50px !important;
  border: 3px solid #a1c057 !important;
}

@media only screen and (min-width: 769px) {
  .item_tabs button {
    border-bottom: 1px solid black !important;
  }

  #leftGrid {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 2%;
  }
  #rightGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "titleGrid titleGrid addToCardGrid"
      "optionsPriceGrid optionsPriceGrid addToCardGrid"
      "locationGrid locationGrid addToCardGrid"
      "nationalityGrid nationalityGrid addToCardGrid"
      "callForPriceGrid callForPriceGrid addToCardGrid"
      "otherInfoGrid otherInfoGrid addToCardGrid";
    width: 68%;
  }

  #mainGridDiv {
    display: flex;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 50px;
    padding-bottom: 20px;
  }

  #otherInfoGrid {
    margin-top: 50px;
  }

  .priceTagUl .price {
    float: right !important;
    text-align: right !important;
  }
  .qtyInput {
    padding-left: 0px !important;
    float: left !important;
  }
  #optionPrice {
    display: flex;
    flex-direction: column-reverse;
  }
  .otherDesktop {
    display: block;
  }
  .otherMobile {
    display: none !important;
  }

  .info-cert-wrapper > img {
    height: 50px !important;
    width: auto !important;
  }
  .infoDownload > a > img {
    height: 50px !important;
    width: auto !important;
  }
  #locationGrid {
    align-self: end;
  }
  #specGrid {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1150px) {
  .mobileBreak {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-row-gap: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sign {
    font-size: 13px !important;
  }
  .each {
    font-size: 10px !important;
  }
  #mainGridDiv {
    display: flex;
    flex-direction: column;
  }

  .mdl-button--raised.mdl-button--colored {
    width: 100% !important;
  }

  #rightGrid {
    display: grid;
    grid-template-areas:
      "titleGrid"
      "fixtureGrid"
      "imageGrid"
      "socialGrid"
      "optionsPriceGrid"
      "priceGrid"
      "callForPriceGrid"
      "supplerCityGrid"
      "reviewGrid"
      "specGrid"
      "certGrid"
      "addToCardGrid"
      "locationGrid"
      "nationalityGrid"
      "showroomSection"
      "otherInfoGrid"
      "clearFix"
      "dummyDiv"
      "itemClick"
      "itemMM"
      "floatClear"
      "row";
  }

  #showroomSection p,
  #showroomSection .c-select {
    margin-bottom: 20px;
  }

  .green-bg-text {
    font-size: 15px !important;
  }
  .green-bg-container {
    display: flex;
  }
  .mobileSpan {
    display: none !important;
  }
  .priceTagUl {
    margin-top: 10px !important;
  }
  .dollars {
    font-size: 25px;
  }
  .dollars .symbol {
    font-size: 14px;
  }
  #fixtureGrid {
    margin-bottom: 15px;
  }
  #titleGrid {
    margin-top: 20px;
  }
  .itemTitleMobile {
    font-size: 18px !important;
  }
  .fixture-style {
    margin-top: 0px !important;
  }
  .feature-item {
    display: grid !important;
  }
  .owl-wrapper {
    width: unset !important;
  }

  .otherMobile {
    display: unset;
  }
  .item_tabs {
    display: flex;
    flex-direction: column;
  }
  .item_tabs button {
    width: 100% !important;
    border-right: 1px solid black !important;
  }

  .qtyInput {
    padding-left: 0px !important;
    float: left !important;
  }
  .ac-skin .vertical .f-row label {
    margin: 0px !important;
  }
  .selected_div {
    padding: 5px 0px !important;
  }
  .priceTagUl .price {
    float: left !important;
    text-align: left !important;
    padding-top: 0px !important;
  }
  .check_nationality {
    display: flex;
    flex-direction: row;
  }
  .item-distributor-block li.price {
    padding-top: 3px !important;
  }
  #socialGrid {
    justify-content: flex-start !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  #socialGrid img {
    width: 25px;
    margin-right: 15px;
  }
  #product_carousel .jcarousel {
    margin: 10px auto !important;
  }
  .ac-skin .f-field {
    margin-top: 10px;
  }
  .item-attr-section fieldset {
    padding-top: 10px !important;
  }
  .quick-specs-list {
    grid-template-columns: 1fr !important;
  }
  .info-cert-wrapper > img {
    height: 40px !important;
  }
  .infoDownload > a > img {
    height: 40px !important;
    width: auto !important;
  }
  .certInfo {
    height: 40px !important;
  }
  .ac-skin .item-main {
    padding-left: 0 !important;
  }

  .item-attr-section label {
    width: auto !important;
    margin-left: 0px !important;
    padding-right: 5px !important;
  }

  .mobileBreak {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }

  .reviews-links {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 0px !important;
  }

  .magnifier-preview {
    margin-top: 0px !important;
  }
  #locationGrid,
  #nationalityGrid {
    margin-top: 0px;
  }
  .miniwishlist-new-count {
    margin-left: 15px !important;
    display: none !important;
  }
  .minicart-new-count {
    display: none !important;
  }
  #nationalityGrid {
    margin-bottom: 10px;
  }
  .check_nationality {
    padding-top: 0px !important;
  }
  .item_tabs button {
    top: 0px !important;
    background-color: #f1f1f1 !important;
  }
  .item_tabs {
    padding: 0px !important;
    background-color: unset !important;
  }
  .item_content {
    border: unset !important;
    padding: 10px !important;
  }

  #prodFamilyMobile {
    background-color: white;
  }
  #prodFamilyItem {
    font-size: 15px !important;
    margin-top: 10px !important;
  }
  .add-review .review-b {
    color: black !important;
  }
  .showroom-info {
    margin-left: 0px !important;
    height: auto !important;
  }
  #showMobile {
    background-color: white !important;
  }

  .bookButton {
    color: black !important;
  }
  .book-viewing-btn-container {
    float: unset !important;
  }
}

#favourite {
  poisition: relative;
  float: left;
}
#preloader {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#preloader > img {
  width: 64px;
  height: 64px;
  align-self: center;
}

.info-cert-wrapper {
  float: left;
  margin-right: 1%;
}
.info-cert-wrapper > img {
  height: 60px;
  vertical-align: middle;
  width: auto;
}

.zoom_img {
  position: absolute;
  width: auto;
  float: left;
  top: 510px !important;
  left: 11%;
}
.Info_download {
  clear: both;
  font-size: 20px;
  padding-top: 15px;
  font-weight: normal;
  color: #000000;
  border-top: solid 1px #cdcdcd;

  font-family: "sqr721ext", sans-serif !important;
}

.item_tabs {
  margin-top: 40px;
  background-color: #f1f1f1;
}

.item_tabs button {
  background-color: inherit;
  border: none;
  outline: none;
  position: relative;
  float: left;
  overflow: visible;
  padding-left: 20px;
  cursor: pointer;
  padding: 0px;
  transition: 0.3s;
  font-size: 12px;
  width: 20%;
  line-height: normal;
  font-family: "sqr721ext", sans-serif !important;
  display: inline-block;
  height: 66px;
  vertical-align: middle;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.item_tabs button:last-of-type {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.item_tabs button.active {
  border-bottom: 1px solid black;
}

.item_tabs button:hover {
  background-color: #ddd;
}

.item_tabs button.active {
  background: #a1c057 !important;
}

.item_content {
  padding: 0px 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
}

.enquiryBtn {
  display: none;
}

.favorite:hover {
  color: #e74c3c;
}
.favorite:active {
  color: #e74c3c;
}
.metadescription {
  width: 100%;
  float: left;
  padding: 10px;
  color: #000;
  font-size: 13px;
}
#gallery-preview {
  width: 100%;
  float: left;
}
#gallery-preview a {
  line-height: 40px;
  color: #fe4f00;
  text-decoration: underline;
}
.fa-star-o:before {
  content: "" !important;
  display: inline-block;
  width: 17px;
  height: 1.75em;
  margin-right: 5px;
}
.fa-star:before {
  content: "" !important;
  display: inline-block;
  width: 17px;
  height: 1.75em;
  margin-right: 5px;
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: inherit !important;
  left: 0px !important;
  opacity: 1 !important;
  float: left !important;
}
.listprice-value {
  text-decoration: line-through;
}
.list-price {
  float: left;
  margin-bottom: 11px !important;
  margin-top: 10px;
  text-align: left !important;
  width: 100% !important;
  font-size: 16px !important;
  color: #333;
}
.space-align {
  margin-bottom: 0px !important;
}
.review {
  display: block !important;
}

span.dollars span.dollars {
  line-height: 80%;
}

.mainProps {
  border-bottom: solid 1px #cdcdcd;
}

#showroomSection select {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  -webkit-appearance: none;
  padding: 0 50px 0 10px;
  cursor: pointer;
  font: 400 14px/1.5 "Open Sans", sans-serif;
  text-indent: 5px;
}

#showroomSection select:focus {
  outline: none;
}

#showroomSection select::-ms-expand {
  display: none;
}

#showroomSection option {
  font: 400 14px/1.5 "Open Sans", sans-serif;
}

#showroomSection .c-select {
  position: relative;
  background: #fff;
  border: 1px solid #a1c057;
  height: 35px;
  cursor: pointer;
}

#showroomSection .c-select:before {
  content: "";
  background: #a1c057;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  pointer-events: none;
}

#showroomSection .c-select:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 5px 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.price {
  float: right !important;
}

.unit-type-active {
  background: #fe4f00 !important;
  padding: 5px 10px;
  color: white !important;
  width: 20%;
  font-weight: bold;
}
.unit-type {
  background: #dcdcdc;
  padding: 5px 10px;
  color: #525252;
  width: 20%;
  font-weight: bold;
}

#desc_properties {
  margin-top: 20px;
}
#desc_properties > p {
  display: none;
}

.spec-wrapper {
  display: flex;
}
.spec-wrapper > div > p {
  margin: 0 0 5px;
}
.specname {
  float: left;
  width: 50%;
  padding: 7px 0px;
}
.quick-specname {
  padding: 5px 0px;
  text-transform: lowercase;
  font-weight: bold;
}
.quick-specname::first-letter {
  text-transform: uppercase;
}
.specvalue {
  float: right;
  width: 45%;
  padding: 7px 0px;
}
.specvalue > p {
  font-size: 13px;
  padding-left: 10px;
}
.quick-specvalue {
  padding: 5px 0px 5px 5px;
}
.specs-subheading {
  font-family: "sqr721ext", sans-serif !important;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.quick-specs-list {
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.specname2 {
  float: left;
  font-weight: bold;
  padding: 7px 7px 7px 0;
}
.specvalue2 {
  float: left;
  padding: 7px 0px;
}
.specvalue2 > p {
  float: left;
  font-size: 13px;
}

.spec-wrapper {
  display: flex;
}
.spec-wrapper > div > p {
  margin: 0 0 5px;
}
.specname {
  float: left;
  width: 50%;
  padding: 7px 0px;
  font-size: 0.9em;
}
.quick-specname {
  padding: 5px 0px;
  text-transform: lowercase;
  font-weight: bold;
}
.quick-specname::first-letter {
  text-transform: uppercase;
}
.specvalue {
  float: right;
  width: 45%;
  padding: 7px 0px;
}
.specvalue > p {
  font-size: 13px;
  padding-left: 10px;
}
.quick-specvalue {
  padding: 5px 0px 5px 5px;
}
.specs-subheading {
  font-family: "sqr721ext", sans-serif !important;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.quick-specs-list {
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.specname2 {
  float: left;
  font-weight: bold;
  padding: 7px 7px 7px 0;
}
.specvalue2 {
  float: left;
  padding: 7px 0px;
}
.specvalue2 > p {
  float: left;
  font-size: 13px;
}

.bookMeetingPopupBack {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.bookMeetingPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 15px;
  padding-top: 0px;
}

.bookMeetingPopup .bookMeetingPopupBody p {
  margin-top: 0px;
  line-height: 1.8em;
}

.bookMeetingPopup h3 {
  background-color: #a1c057;
  display: block;
  text-align: center;
  line-height: 2em;
  font-size: 1.2em;
  color: #000000;
  font-weight: normal;
  text-transform: uppercase;
}

.bookMeetingPopup p input[type="checkbox"] {
  left: 0px;
  position: relative;
  opacity: 1;
  margin-left: 15px;
  margin-bottom: 10px;
}

.bookMeetingPopupFoot {
  margin-top: 30px;
}
.bookMeetingPopupFoot a {
  width: 48%;
  margin: 0px !important;
}

.bookMeetingPopupFoot a:first-of-type,
.bookMeetingPopupFoot a:first-of-type:hover {
  margin-right: 2% !important;
}

.meetingCancelBtn,
.meetingConfirmBtn {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #ffffff !important;
  position: relative;
  height: 36px;
  min-width: 64px;
  padding: 0 18px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  overflow: hidden;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  text-transform: uppercase;
}

.meetingCancelBtn:hover,
.meetingConfirmBtn:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}

.meetingCancelBtn {
  background-color: #fe4f00;
}

.meetingConfirmBtn {
  background-color: #a1c057;
}

.datepicker.datepicker-inline {
  margin-left: auto;
  margin-right: auto;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background-color: #fe4f00 !important;
  border-color: #fe4f00 !important;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rating-home-img {
  width: auto !important;
  text-align: center;
  margin: 0 auto;
}

.item-title {
  width: unset;
  border-bottom: 1px solid #ccc;
  width: 70%;
}
.owl-prev,
.owl-next {
  background: none !important;
  border: none !important;
}
.owl-carousel .owl-item a img {
  height: 150px !important;
}
.currency {
  color: #fe4f00;
  font-weight: 600 !important;
}
.home-price {
  text-align: center !important;
  color: #fe560a;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 0px !important;
}

.cad-span {
  font-weight: 700;
  font-size: 11px;
  position: relative;
  bottom: 2px;
}
.feature-item {
  background: #a1c057;
  padding: 0px 21px;
  width: 100%;
  font-size: 16px;
  /*margin-top: 22px;*/
  font-weight: 700;
  font-family: "sqr721ext", sans-serif !important;
  display: flex;
  justify-content: space-between;
  line-height: 45px;
}
.item-bottom {
  font-family: "sqr721ext", sans-serif !important;
  font-weight: 700;
}

/*PRODUCT CAROUSEL START*/
#product_carousel {
  position: relative;
  width: auto;
  height: auto;
  overflow: hidden;
  height: 175px;
}

#product_carousel .controls {
  display: inline-block;
  width: 100%;
}
#js-item-image-158520 {
  width: 180px;
  text-align: center;
  margin: 0 auto;
  height: 350px;
}

#product_carousel .controls .prev {
  width: 30px;
  height: 30px;
  float: left;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font-size: 15px;
}

#product_carousel .controls .next {
  width: 30px;
  height: 30px;
  float: right;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font-size: 15px;
}

#product_carousel .controls .next span,
#product_carousel .controls .prev span {
  line-height: 28px;
}

#product_carousel .jcarousel {
  width: 100%;
  margin: 20px auto;
}

#product_carousel .jcarousel ul {
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

#product_carousel .jcarousel li {
  position: relative;
  float: left;
  width: 68px;
  height: auto;
  margin-right: 3px;
}

#product_carousel .jcarousel img {
  width: 100%;
  height: 64px;
  object-fit: contain;
}

#product_carousel .jcarousel img:hover {
  transition: all 0.5s ease 0s;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

/*PRODUCT CAROUSEL END*/

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.whatshot-icon {
  animation: blink 1s infinite ease-in-out;
}

html {
  scroll-behavior: smooth;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.wrapper {
  border: 1px solid #ddd;
}

.title {
  padding: 10px;
  background-color: #eee;
  font-size: 2em;
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title i {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.title i:hover {
  background-color: #fff;
}

.review {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.review:not(:last-of-type) {
  margin-bottom: 15px;
}

.reviewContent {
  padding: 10px 5px 10px 5px;
}

.ratingSide {
  padding: 10px;
}

.reviewDetails {
  line-height: initial;
  letter-spacing: initial;
  padding: 10px 0;
}

.ratingStarsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.reviewTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.reviewTitle .reviewedBy {
  padding: 10px 0 0 5px;
}

.reviews {
  padding: 10px;
  max-height: 600px;
  overflow: auto;
  border-top: 1px solid #c8c8c8;
}

.reviewLinksContainer {
  padding: 10px 0;
}

.reviewStarAndBoxWrapper {
  display: flex;
}

.totalReviewsWrapper {
  padding: 5px 0 0 5px;
}

@media all and (min-width: 1024px) {
  .ratingSideContainer {
    display: flex;
    width: 100%;
  }
  .ratingSide {
    min-width: 320px;
    width: 38%;
  }

  .reviews {
    border-top: none;
    width: 60%;
    margin-left: auto;
  }
}

.wrapper {
    margin: 20px;
}

.imageWrapper {
    max-width: 100%;
}

.imageWrapper img {
    width: 100%;
}

.titleWrapper {
    margin-top: 15px;
}

.flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.distance {
    font-weight: bold !important;
    font-size: 12px !important;
    border: 1px solid lightgray;
    background: whitesmoke;
    display: flex !important;
    align-items: center;
    padding: 8px !important;
    border-radius: 2px;
}

.titleWrapper span {
    display: block;
    font-weight: 400;
    padding: 3px 0;
    font-size: 12px;
    height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.title {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.withIcon {
    display: flex !important;
    align-items: center !important;
    margin: 3px 0;
}

.withIcon > i {
    font-size: 18px !important;
    margin-right: 10px;
}

.phone {
    color:#1470f5;
    font-weight: 600;
    /* margin-top: 15px; */
}
:root {
  --gutter: 20px;
}

.horizontal-scrolling {
  padding: 15px 0 0 0;
  display: grid;
  grid-gap: var(--gutter) 0;
  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;
}

.horizontal-scrolling > * {
  grid-column: 2 / -2;
}

.horizontal-scrolling > .full {
  grid-column: 1 / -1;
}

.hs {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 10px;
  grid-template-rows: 110px;
  grid-auto-flow: column;
  grid-auto-columns: calc(150px - var(--gutter) * 2);
  overflow-x: auto;
  scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * var(--gutter));
}

.hs:before,
.hs:after {
  content: "";
  width: 10px;
}

/* Demo styles */

.horizontal-scrolling {
  border: 1px solid #c8c8c8;
}

.hs > li,
.horizontal-scrolling .item {
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.review-photos--title {
  padding: 10px 0;
}

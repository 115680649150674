.also-available-container {
  padding: 0 15px;
}

.also-available-wrapper {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.also-available-header {
  margin: 0 !important;
  border-bottom: none;
  background-color: #eee !important;
}

.itemDistributors {
  padding: 0 !important;
  overflow-x: auto;
}
.addToCartBtn span {
  display: flex;
  align-items: center;
}

.vendor-list-price-column {
  font-weight: bold;
  font-size: 18px;
}

#myTable {
  border: none;
  margin: 0;
}

#myTable .qtyControlsBox {
  margin: 0 auto;
}
.itemDistributors table td {
  padding: 8px !important;
}

select {
  padding: 0 !important;
}

.form-control {
  padding-left: 0 !important;
}

.varients_dropdown {
  min-width: 90px;
}

@media all and (max-width: 768px) {
  #myTable {
    font-size: 12px;
  }

  .vendor-list-price-column {
    font-size: 16px;
  }

  .itemDistributors table .addToCartBtn {
    width: 64px;
  }
}

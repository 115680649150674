.message-box-wrapper {
  padding: 0;
}
.message-box-table {
  margin-bottom: 5px;
}

.message-box-table-header {
  box-shadow: inset 0 -1px 1px 0px #c8c8c8;
}

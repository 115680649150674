.menuLi {
  float: left;
  position: relative;
  padding: 30px;
  line-height: 45px;
  text-transform: uppercase;
  font-size: 14px;
}

.menuLi:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
}

.menuLi:hover .dropdown-content {
  display: block;
  background: black;
  position: fixed;
  width: 76%;
  top: 105px;
  margin: 0 10%;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 3%;
}

.dropdown-li {
  list-style: none;
  position: relative;
}
.dropdown-content:hover {
  display: block;
}

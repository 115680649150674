.outBox {
  font-size: 14px;
  line-height: unset;
  font-weight: 600;
  box-sizing: border-box;
  border: 1px solid black;
  /* #807973 */
  display: inline-block;
  padding: 2px;
  text-align: center;
  text-rendering: optimizeLegibility;
  color: #33302e;
  font-family: MetricWeb, sans-serif;
  width: 128px;
  margin-left: 20px;
}

.outBox label {
  display: inline-block;
  cursor: pointer;
  float: left;
  /* margin: 0;
    min-height: 28px;
    min-width: 8ch; */
  position: relative;
  height: 100%;
  width: 50%;
  /* padding: 0px; */
}

/* label:not(:first-of-type) {
    border-left: 1px solid #807973;
} */

.outBox .switch1-on {
  background-color: #0d7680;
  color: #fff;
  box-sizing: border-box;
  display: inline-block;
  max-height: 38px;
  padding: 8px 10px 8px 10px;
  transition: 0.3s background-color, 0.15s color ease-out;
  white-space: nowrap;
  width: 100%;
}

.outBox .switch2-on {
  background-color: #08474d;
  color: #fff;
  box-sizing: border-box;
  display: inline-block;
  max-height: 38px;
  padding: 8px 10px 8px 10px;
  transition: 0.3s background-color, 0.15s color ease-out;
  white-space: nowrap;
  width: 100%;
}

.outBox .switch1-off {
  /* background-color: #0d7680; */
  color: #0d7680;
  box-sizing: border-box;
  display: inline-block;
  max-height: 38px;
  padding: 8px 10px 8px 10px;
  transition: 0.3s background-color, 0.15s color ease-out;
  white-space: nowrap;
  width: 100%;
}

.outBox .switch2-off {
  /* background-color: #0d7680; */
  color: #0d7680;
  box-sizing: border-box;
  display: inline-block;
  max-height: 38px;
  padding: 8px 10px 8px 10px;
  transition: 0.3s background-color, 0.15s color ease-out;
  white-space: nowrap;
  width: 100%;
}
